import TwitterIcon from '@mui/icons-material/Twitter'
import logo from './logo512.png'
import layerzero from './layerzero-blog-post-img.png'
import normal from './normal.png'
import pwnmanship from './pwnmanship.jpeg'
import controlz from './controlz.jpeg'
import immunefiBlockian from './immunefi-blockian.jpeg'
import './App.css'
import { useEffect } from 'react'

const blogPosts = [
  "https://medium.com/@blockian/blackboxing-layerzero-labs-off-chain-relayer-954aecab0f62",
  "https://medium.com/@blockian/striking-gold-at-30-000-feet-uncovering-a-critical-vulnerability-in-q-blockchain-for-50-000-ab335042147b",
  "https://twitter.com/immunefi/status/1677390037053218819"
]

function App() {
  useEffect(() => {
    const observer = new IntersectionObserver(((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
        }
      })
    }))

    setTimeout(() => {
      const hiddenElements = document.querySelectorAll('.hidden')
      hiddenElements.forEach(el => observer.observe(el))
    }, 10)
  }, [])
  return (
    <div className="app">
      <div className="header">
        <div className="header-item">
          <img src={logo} className="blockian-logo" alt="logo" />
          <p className="logo-text" >Blockian</p>
        </div>
        <div className="header-item">
          <a rel="noreferrer" href="https://twitter.com/_blockian" target="_blank">
            <TwitterIcon fontSize="large" />
          </a>
        </div>
      </div>
      <div className="simple-container">
        <div className="content-container">
          <h1 className="hidden">About Blockian</h1>
          <p className="hidden first">Blockian is a duo of independent smart contract security researchers. The team includes <a rel="noreferrer" href="https://twitter.com/pwnmansh1p" target="_blank">pwnmansh1p</a>, who brings over a decade of security research experience to the table, and <a rel="noreferrer" href="https://twitter.com/controlz_1337" target="_blank">ControlZ</a>, a former software architect now specializing in security research.</p>
          <p className="hidden first">Blockian uncovered numerous vulnerabilities across various protocols and are committed to improving the blockchain landscape</p>
        </div>

        <div className="content-container">
          <h1 className="hidden">Blog Posts</h1>
          <a rel="noreferrer" href={blogPosts[0]} target="_blank" className="blog-post-container hidden first">
            <img src={layerzero} className="blog-post-img" alt="logo" />
            <div className="blog-post-content-container">
              <div className="blog-post-content-header">medium.com</div>
              <div className="blog-post-content">Blackboxing LayerZero Labs' off-chain Relayer</div>
              <div className="blog-post-content-footer">How I found a bug in an off-chain infrastructure for 25K$</div>
            </div>
          </a>
          <a rel="noreferrer" href={blogPosts[1]} target="_blank" className="blog-post-container hidden second">
            <img src={normal} className="blog-post-img" alt="logo" />
            <div className="blog-post-content-container">
              <div className="blog-post-content-header">medium.com</div>
              <div className="blog-post-content">Striking Gold at 30,000 Feet: Uncovering...</div>
              <div className="blog-post-content-footer">It was September 2022. For months, my friend and I had been toying with the idea of hunting for bugs i...</div>
            </div>
          </a>
          <a rel="noreferrer" href={blogPosts[2]} target="_blank" className="blog-post-container hidden third">
            <img src={immunefiBlockian} className="blog-post-img" alt="logo" />
            <div className="blog-post-content-container">
              <div className="blog-post-content-header">twitter.com</div>
              <div className="blog-post-content">#whitehatsuccess story time.</div>
              <div className="blog-post-content-footer">1/ #whitehatsuccess story time. Meet the two 25-year-olds (@pwnmanship and @ContorlZ_1337) behind the...</div>
            </div>
          </a>
        </div>

        <div className="content-container">
          <h1 className="hidden">Our Team</h1>
          <div className="out-team-container-main">
            <div className="out-team-container hidden">
              <img src={pwnmanship} className="out-team-img" alt="logo" />
              <div className="out-team-content-container">
                <div className="out-team-content-header">pwnmansh1p</div>
                <div className="out-team-content">Boasting a decade of expertise in security research, complemented by a Master's degree in Computer Science.</div>
                <div className="out-team-content-footer">
                  <a rel="noreferrer" href="https://twitter.com/pwnmansh1p" target="_blank">
                    <TwitterIcon fontSize="small" />
                  </a>
                </div>
              </div>
            </div>
            <div className="out-team-container hidden first">
              <img src={controlz} className="out-team-img" alt="logo" />
              <div className="out-team-content-container">
                <div className="out-team-content-header">ControlZ</div>
                <div className="out-team-content">From software architect to security expert, transitioned from hacking Android devices to hunting web3 bugs.</div>
                <div className="out-team-content-footer">
                  <a rel="noreferrer" href="https://twitter.com/controlz_1337" target="_blank">
                    <TwitterIcon fontSize="small" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-container show">
          <div className="footer">© 2023 Blockian</div>
        </div>
      </div>
    </div>
  );
}

export default App;
